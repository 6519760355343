import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLayoutDesktopContext } from '../../contexts/LayoutDesktopContext';
import SidebarMenu from './SidebarMenu';
import icon_tierra from '../assets/images/icon_tierra.png';

const { Sider } = Layout;

const SlideBar = () => {
  const navigate = useNavigate();
  const { collapsedslidebar, onCollapsedSlideBar } = useLayoutDesktopContext();

  const handleLogoClick = () => {
    // Điều hướng đến trang chủ khi click vào logo
    navigate('/');
  };

  return (
    <Sider
      collapsible
      collapsed={collapsedslidebar}
      onCollapse={(value) => onCollapsedSlideBar(value)}
      width={250}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 502,
      }}
      theme="light"
    >
      <LogoLayout onClick={handleLogoClick}>
        <LogoImage
          src={icon_tierra}
          alt="tierra logo"
          style={{ width: `${collapsedslidebar ? '50px' : 'auto'}`, objectFit: 'contain' }}
        />
      </LogoLayout>
      <SidebarMenu />
    </Sider>
  );
};

const LogoImage = styled.img`
  margin-top: 0.2rem;
  height: 50px;
`;

const LogoLayout = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 5px 0;
`;

export default SlideBar;
