import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import publishReducer from '../features/published/publishSlice';
import pollingReducer from '../features/rawData/pollingSlice';

export const store = configureStore({
  reducer: {
    polling: pollingReducer,
    publish: publishReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const initialRootState = {
  ...store.getState(),
};

export const persistor = persistStore(store);
