import queryString from 'query-string';
import axiosClient from './axiosClient';

const rawDataApi = {
  getlistRawData: (query, payload) => {
    const response = axiosClient.post(
      `/v1/api/diamond/search?${queryString.stringify(query)}`,
      payload,
    );
    return response;
  },
  getNewData: () => {
    const response = axiosClient.get(`v1/api/diamond/action-get-raw-data`);
    return response;
  },
  getStatusRawData: () => {
    const response = axiosClient.get(`/v1/api/diamond/job-log/raw-data-diamond`);
    return response;
  },
};

export default rawDataApi;
