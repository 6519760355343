import { setItem, getJson, removeItem } from './storage';

export const setUser = (user) => {
  setItem('userAdminTierra', JSON.stringify(user));
};

export const getUser = () => {
  return getJson('userAdminTierra');
};

export const logout = () => {
  removeItem('userAdminTierra');
};

export const getToken = () => {
  const localUser = getUser();
  if (!localUser) {
    return null;
  }
  return localUser.accessToken;
};

export const removeStorage = () => {
  localStorage.removeItem('tierra.userAdminTierra');
  localStorage.removeItem('persist:root');
};

export const getLanguage = () => {
  return getJson('language');
};
