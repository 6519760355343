import iconEmerald from '../features/assets/images/emerald.webp';
import iconHeart from '../features/assets/images/heart.png';
import iconMarquise from '../features/assets/images/marquise.webp';
import iconOval from '../features/assets/images/oval.webp';
import iconPear from '../features/assets/images/pear.webp';
import iconPrincess from '../features/assets/images/princess.webp';
import iconRadiant from '../features/assets/images/radiant.webp';
import iconRound from '../features/assets/images/round.webp';
import iconSquare from '../features/assets/images/square.png';
import iconTriangle from '../features/assets/images/triangle.png';
import iconOthers from '../features/assets/images/others.png';

export const LOCAL_STORAGE_PREFIX = 'tierra';

export const ROOT_API = process.env.REACT_APP_BASE_URL;

export const COLOR_AVATAR_USER = ['#e8430f', '#374151', '#1d4ed8', '#047857', '#b45309'];

export const listShapes = [
  {
    key: 1,
    label: 'Round',
    value: 'ROUND',
    icon: iconRound,
  },
  {
    key: 2,
    label: 'Pear',
    value: 'PEAR',
    icon: iconPear,
  },
  {
    key: 3,
    label: 'Emerald',
    value: 'EMERALD',
    icon: iconEmerald,
  },
  {
    key: 4,
    label: 'Oval',
    value: 'OVAL',
    icon: iconOval,
  },
  {
    key: 5,
    label: 'Heart',
    value: 'HEART',
    icon: iconHeart,
  },
  {
    key: 6,
    label: 'Cushion',
    value: 'CUSHION',
    icon: iconSquare,
  },
  {
    key: 7,
    label: 'Marquise',
    value: 'MARQUISE',
    icon: iconMarquise,
  },
  {
    key: 8,
    label: 'Radiant',
    value: 'RADIANT',
    icon: iconRadiant,
  },
  {
    key: 9,
    label: 'Square Emerald',
    value: 'SQUARE EMERALD',
    icon: iconSquare,
  },
  {
    key: 10,
    label: 'Princess',
    value: 'PRINCESS',
    icon: iconPrincess,
  },
  {
    key: 11,
    label: 'Triangle',
    value: 'TRIANGLE',
    icon: iconTriangle,
  },
  {
    key: 12,
    label: 'others',
    value: 'OTHERS',
    icon: iconOthers,
  },
];
export const optionsCarat = [
  {
    label: 'Less Than	<',
    value: 'lessThan',
    calculation: '<',
  },
  {
    label: 'Less Than Or Equal	<=',
    value: 'lessThanOrEqual',
    calculation: '<=',
  },
  {
    label: 'Greater Than	>',
    value: 'greaterThan',
    calculation: '>',
  },
  {
    label: 'Greater Than Or Equal	>=',
    value: 'greaterThanOrEqual',
    calculation: '>=',
  },
  {
    label: 'Equal	=',
    value: 'equal',
    calculation: '=',
  },
  {
    label: 'Not Equal	!=',
    value: 'notEqual',
    calculation: '!=',
  },
  {
    label: 'Range Inclusive <= and <=',
    value: 'rangeInclusive',
    calculation: '<= and <=',
  },
  {
    label: 'range Exclusive	< and <',
    value: 'rangeExclusive',
    calculation: '< and <',
  },
  {
    label: 'Range Left Inclusive Right Exclusive <= and <',
    value: 'rangeLeftInclusiveRightExclusive',
    calculation: '<= and <',
  },
  {
    label: 'Range Left Exclusive Right Inclusive	< and <=',
    value: 'rangeLeftExclusiveRightInclusive',
    calculation: '< and <=',
  },
];
export const oneValueCarat = [
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'equal',
  'notEqual',
];
export const twoRangeCarat = [
  'rangeInclusive',
  'rangeExclusive',
  'rangeLeftInclusiveRightExclusive',
  'rangeLeftExclusiveRightInclusive',
];
export const listColor = [
  {
    label: 'D',
    value: 'D',
  },
  {
    label: 'E',
    value: 'E',
  },
  {
    label: 'F',
    value: 'F',
  },
  {
    label: 'G',
    value: 'G',
  },
  {
    label: 'H',
    value: 'H',
  },
  {
    label: 'I',
    value: 'I',
  },
  {
    label: 'J',
    value: 'J',
  },
  {
    label: 'K',
    value: 'K',
  },
  {
    label: 'L',
    value: 'L',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'N',
    value: 'N',
  },
  {
    label: 'N-O',
    value: 'N-O',
  },
];
export const listClarity = [
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'IF',
    value: 'IF',
  },
  {
    label: 'VVS1',
    value: 'VVS1',
  },
  {
    label: 'VVS2',
    value: 'VVS2',
  },
  {
    label: 'VS1',
    value: 'VS1',
  },
  {
    label: 'VS2',
    value: 'VS2',
  },
  {
    label: 'SI1',
    value: 'SI1',
  },
  {
    label: 'SI2',
    value: 'SI2',
  },
  {
    label: 'I1',
    value: 'I1',
  },
];
export const listCoefficient = [
  {
    label: 'B1',
    value: 3,
  },
  {
    label: 'B2',
    value: 4,
  },
  {
    label: 'B3',
    value: 5,
  },
  {
    label: 'B4',
    value: 1,
  },
  {
    label: 'B5',
    value: 2,
  },
  {
    label: 'A1',
    value: 100,
  },
  {
    label: 'A2',
    value: 200,
  },
  {
    label: 'A3',
    value: 300,
  },
];
