import {
  CalculatorOutlined,
  ContainerOutlined,
  ProfileOutlined,
  SettingOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUser } from '../../utils/auth';

const SidebarMenu = () => {
  const items = [
    {
      key: '/raw-data',
      icon: <SolutionOutlined />,
      label: 'Raw data',
      permissions: 'RAW_DATA_VIEW_ACTION',
    },
    {
      key: '/waiting-for-publishing',
      icon: <ContainerOutlined />,
      label: 'Waiting for publishing',
      permissions: 'WAITING_PUBLISH_VIEW_ACTION',
    },
    {
      key: '/published',
      icon: <ProfileOutlined />,
      label: 'Published',
      permissions: 'PUBLISH_VIEW_ACTION',
    },
    {
      key: '/calculation',
      icon: <CalculatorOutlined />,
      label: 'Calculation formula',
      permissions: 'CALCULATE_VIEW_ACTION',
    },
    {
      key: '/account',
      icon: <UsergroupAddOutlined />,
      label: 'Accounts',
      permissions: 'PROFILE_UPDATE_ACTION',
    },
    {
      key: '/configurations',
      icon: <SettingOutlined />,
      label: 'Configurations',
      permissions: 'CONFIGURATION_VIEW_ACTION',
      children: [
        { key: '/coefficient', label: 'Coefficient' },
        { key: '/cost', label: 'Cost' },
        { key: '/shape', label: 'Shape' },
        { key: '/currency', label: 'Currency' },
      ],
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const dataUserPermission = getUser().user.permissions;

  const [activeRoute, setActiveRoute] = useState(location.pathname);

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location.pathname]);

  const openKeys = items
    .filter((item) => item.children?.some((subItem) => location.pathname.startsWith(subItem.key)))
    .map((item) => item.key);

  return (
    <Menu
      mode="inline"
      theme="light"
      selectedKeys={[activeRoute]}
      defaultOpenKeys={openKeys}
      onClick={({ key }) => {
        if (!key) return;
        setActiveRoute(key);
        navigate(key);
      }}
    >
      {items.map((item) => {
        if (dataUserPermission.includes(item.permissions)) {
          if (item.children) {
            return (
              <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.children.map((subItem) => (
                  <Menu.Item key={subItem.key}>{subItem.label}</Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          } else {
            return (
              <Menu.Item key={item.key} icon={item.icon}>
                {item.label}
              </Menu.Item>
            );
          }
        }
        return null;
      })}
    </Menu>
  );
};
export default SidebarMenu;
