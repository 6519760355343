import { LogoutOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getUser, removeStorage } from '../../utils/auth';
import AvatarUser from './Avatar';

const Header = () => {
  const listMenu = [
    {
      key: 1,
      name: 'Đăng xuất',
      icon: <LogoutOutlined />,
      onClick: () => handleClickLogout(),
    },
  ];

  const user = getUser();

  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleClickAvatar = () => {
    setShowMenu(!showMenu);
  };
  const handleClickLogout = () => {
    removeStorage();
    navigate('/login');
    window.location.reload();
  };

  return (
    <WrapperHeader>
      <WrapperAvatar>
        <AvatarUser onClick={handleClickAvatar} fullName={user.user.fullName} />
        {showMenu && (
          <WrapperHeaderMenu>
            <ul>
              {listMenu.map((item) => {
                return (
                  <li onClick={item.onClick}>
                    {item.icon}
                    <span>{item.name}</span>
                  </li>
                );
              })}
            </ul>
          </WrapperHeaderMenu>
        )}
      </WrapperAvatar>
    </WrapperHeader>
  );
};

const WrapperHeader = styled.div`
  background: white;
  padding: 10px 20px;
  display: flex;
  justify-content: end;
`;
const WrapperAvatar = styled.div`
  position: relative;
`;
const WrapperHeaderMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  border-radius: 5px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 180px;
    padding: 10px;
    li {
      display: flex;
      gap: 10px;
      cursor: pointer;
      padding: 10px 5px;
      border-radius: 5px;
    }
    li:hover {
      background-color: #e6f4ff;
      color: #1677ff;
    }
  }
`;

export default Header;
