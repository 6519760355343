import { createContext, useContext, useMemo, useState } from 'react';

const initialContext = {
  collapsedslidebar: false,
  onCollapsedSlideBar: () => {},
};

const LayoutDesktopContext = createContext(initialContext);

export const LayoutDesktopProvider = ({ children }) => {
  const [collapsedslidebar, setCollapsedSlideBar] = useState(false);
  const value = useMemo(() => {
    const onCollapsedSlideBar = (collapsed) => {
      setCollapsedSlideBar(collapsed);
    };

    return {
      collapsedslidebar,
      onCollapsedSlideBar,
    };
  }, [collapsedslidebar]);
  return <LayoutDesktopContext.Provider value={value}>{children}</LayoutDesktopContext.Provider>;
};

export const LayoutDesktopConsumer = ({ children }) => {
  return (
    <LayoutDesktopContext.Consumer>
      {(context) => {
        return children(context);
      }}
    </LayoutDesktopContext.Consumer>
  );
};

export const useLayoutDesktopContext = () => useContext(LayoutDesktopContext);
