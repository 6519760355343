import { ConfigProvider, Layout, Spin } from 'antd';
import { Suspense, lazy } from 'react';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import styled from 'styled-components';

import './App.css';
import { LayoutDesktopProvider, useLayoutDesktopContext } from './contexts/LayoutDesktopContext';
import Header from './features/components/Header';
import SlideBar from './features/components/SlideBar';
import LoginFeature from './features/login';
import { getUser } from './utils/auth';

const AccountFeature = lazy(() => import('./features/account'));
const RawDataFeature = lazy(() => import('./features/rawData'));
const WaitingPublishFeature = lazy(() => import('./features/waitingPublish'));
const PublishedFeature = lazy(() => import('./features/published'));
const CalculationFeature = lazy(() => import('./features/calculation'));
const NotFoundPage = lazy(() => import('./features/notFound'));
const ConfigurationFeature = lazy(() => import('./features/configuration'));
const ConfigurationPriceFeature = lazy(() => import('./features/configPrice'));
const ConfigurationCurrencyFeature = lazy(() => import('./features/currency'));
const ConfigurationShapeFeature = lazy(() => import('./features/configShape'));

function App() {
  function RequireAuth({ children }) {
    const datauser = getUser();

    return datauser ? children : <Navigate to="/login" replace />;
  }

  function RequirePermission({ children, requiredPermission }) {
    const datauser = getUser();
    const userPermissions = datauser?.user?.permissions || [];
    const hasPermission = userPermissions.includes(requiredPermission);

    return hasPermission ? children : <Navigate to="/not-found" replace />;
  }

  return (
    <ConfigProvider>
      <Router>
        <LayoutDesktopProvider>
          <Suspense fallback={<div>Đang tải...</div>}>
            <Routes>
              <Route
                path="/"
                element={
                  getUser() ? <Navigate to="/raw-data" replace /> : <Navigate to="/login" replace />
                }
              />
              <Route path="/not-found" element={<NotFoundPage />} />
              <Route
                path="/login"
                element={getUser() ? <Navigate to="/raw-data" replace /> : <LoginFeature />}
              />

              <Route
                element={
                  <RequireAuth>
                    <MainLayout />
                  </RequireAuth>
                }
              >
                <Route
                  path="/account"
                  element={
                    <RequirePermission requiredPermission="PROFILE_UPDATE_ACTION">
                      <AccountFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/raw-data"
                  element={
                    <RequirePermission requiredPermission="RAW_DATA_VIEW_ACTION">
                      <RawDataFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/waiting-for-publishing"
                  element={
                    <RequirePermission requiredPermission="WAITING_PUBLISH_VIEW_ACTION">
                      <WaitingPublishFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/published"
                  element={
                    <RequirePermission requiredPermission="PUBLISH_VIEW_ACTION">
                      <PublishedFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/calculation"
                  element={
                    <RequirePermission requiredPermission="CALCULATE_VIEW_ACTION">
                      <CalculationFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/coefficient"
                  element={
                    <RequirePermission requiredPermission="CONFIGURATION_VIEW_ACTION">
                      <ConfigurationFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/cost"
                  element={
                    <RequirePermission requiredPermission="CONFIGURATION_VIEW_ACTION">
                      <ConfigurationPriceFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/currency"
                  element={
                    <RequirePermission requiredPermission="CONFIGURATION_VIEW_ACTION">
                      <ConfigurationCurrencyFeature />
                    </RequirePermission>
                  }
                />

                <Route
                  path="/shape"
                  element={
                    <RequirePermission requiredPermission="CONFIGURATION_VIEW_ACTION">
                      <ConfigurationShapeFeature />
                    </RequirePermission>
                  }
                />
              </Route>

              <Route path="*" element={<Navigate to="/not-found" replace />} />
            </Routes>
          </Suspense>
        </LayoutDesktopProvider>
      </Router>
    </ConfigProvider>
  );
}

export default App;

function MainLayout() {
  const { collapsedslidebar } = useLayoutDesktopContext();
  return (
    <LayoutPageStyled hasSider>
      <SlideBar />
      <Layout
        className="content-layout"
        style={{ marginLeft: `${collapsedslidebar ? '80px' : '250px'}`, height: '100%' }}
      >
        <Suspense
          fallback={
            <WrapperLoadingContent>
              <Spin size="large" />
            </WrapperLoadingContent>
          }
        >
          <Header />
          <WrapperContent>
            <Outlet /> {/* Outlet render các component con của route này */}
          </WrapperContent>
        </Suspense>
      </Layout>
    </LayoutPageStyled>
  );
}

export const LayoutPageStyled = styled(Layout)`
  height: 100%;
`;
export const WrapperContent = styled.div`
  padding: 10px;
`;
export const WrapperLoadingContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
