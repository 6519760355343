import { Avatar } from 'antd';

const AvatarUser = (props) => {
  const { fullName } = props;

  function formatAvatarName(name) {
    const words = name.trim().split(/\s+/);
    const length = words.length;

    if (length === 1) {
      return words[0].charAt(0);
    } else if (length === 2) {
      return words[0].charAt(0) + words[1].charAt(0);
    } else {
      return words[length - 2].charAt(0) + words[length - 1].charAt(0);
    }
  }

  return (
    <>
      <span style={{ marginRight: '10px' }}>{fullName}</span>
      <Avatar {...props} style={{ verticalAlign: 'middle', cursor: 'pointer' }} size="large">
        {formatAvatarName(fullName)}
      </Avatar>
    </>
  );
};

export default AvatarUser;
