import axiosClient from './axiosClient';

const authApi = {
  login: (data) => {
    const response = axiosClient.post(`/v1/api/auth/signin`, data);
    return response;
  },
};

export default authApi;
